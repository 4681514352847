<template>
  <v-container fluid>
    <v-sheet rounded min-height="600">
      <common-error
        v-if="isError"
        @fetchData="fetchData"
      />
      <v-skeleton-loader
        v-else-if="isLoading"
        type="
        card-heading,
        list-item-three-line,
        list-item-three-line,
        list-item-three-line
        "
      ></v-skeleton-loader>
      <vue-editor
        v-else
        disabled
        v-model="parameterValue"
      >
      </vue-editor>
    </v-sheet>
  </v-container>
</template>

<script>
import { VueEditor } from 'vue2-editor';

export default {
  name: 'contact-shipper',
  components: {
    VueEditor,
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      parameterValue: '',
    };
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      this.isError = false;
      try {
        const res = await this.$_services.wording.aboutInfo();
        this.parameterValue = res.data.parameterValue;
      } catch {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};

</script>

<style lang="scss" scoped>
  ::v-deep {
    .ql-align-left {
      text-align: left;
    }
    .ql-align-right {
      text-align: right;
    }
    .ql-align-center {
      text-align: center;
    }
    .ql-toolbar {
      display: none;
    }
    .border {
      border-top: 1px solid grey;
    }
    .ql-container.ql-snow{
      border: none !important;
    }
    p {
      margin-bottom: 0px !important;
    }
  }
</style>
